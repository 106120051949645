import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";

import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";

import TextError from "../../components/notifications/TextError";
import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout from "../../components/layout/layout";

import { CalendarIcon } from "@heroicons/react/outline";
import { nationalities } from "../../constants/nationalities";

import SEO from "../../components/seo";

const VolunteerForm = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthdateError, setBirthDateError] = useState("");
  const [volunteerExp, setVolunteerExp] = useState("");
  const [declare, setDeclare] = useState(false);
  const [agree, setAgree] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitted, isSubmitting, isValid },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      name: "",
      birthday: "",
      nationality: "",
      marital: "",
      occupation: "",
      school: "",
      employer: "",
      languagespoken: "",
      languagewritten: "",
      home: "",
      mobile: "",
      email: "",
      address: "",
      type: "",
      volunteerothers: "",
      experience: "",
      experiencedetail: "",
      commitment: "",
      message: "",
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  if (errors) {
    console.log("errors: ", errors);
  }
  console.log("isDirty", isDirty);
  console.log("isValid", isValid);
  console.log("isSubmitted", isSubmitted);

  let count;

  if (watch("message")) {
    count = watch("message").length;
  } else {
    count = 0;
  }

  if (watch("occupation")) {
    console.log("occupation: ", watch("occupation"));
  }
  if (watch("birthday")) {
    console.log("birthday", watch("birthday"));
  }

  const types = [
    { name: "Photography", value: "photography" },
    { name: "Creative design", value: "design" },
    { name: "Organising events", value: "event" },
    { name: "Administrative support", value: "admin" },
    { name: "Befriender", value: "befriender" },
    { name: "Social enterprise", value: "social_enterprise" },
    { name: "Arts and Craft", value: "arts_and_craft" },
    { name: "Singing & Music", value: "singing_and_music" },
    { name: "Education", value: "education" },
    { name: "Cooking", value: "cooking" },
    { name: "General Maintenance", value: "general_maintenance" },
    { name: "Others", value: "others" },
  ];

  const onSubmit = async data => {
    console.log("onSubmit");
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }
    if (birthDay === "") {
      setBirthDateError("empty");
      // console.log("birthday error triggered");
      scrollToBirthDay();
      return;
    }
    if (birthDay && birthDay !== "") {
      const formattedDate = moment(birthDay).format("LL");
      data.birthday = formattedDate;
    }

    if (data.home !== "") {
      console.log("home:", data.home);
      const homeData = data.home.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
      console.log("converted home", homeData);
      data.home = homeData;
    }
    if (data.mobile !== "") {
      console.log("mobile:", data.mobile);
      const mobileData = data.mobile.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
      console.log("converted mobile", mobileData);
      data.mobile = mobileData;
    }

    delete data.honey;
    console.log("submitted data: ", data);

    let airtableURL;
    if (data) {
      airtableURL = `${process.env.GATSBY_API_STATIC}/addvolunteer`;
      // airtableURL = `${process.env.REACT_APP_API_STATIC}/addvolunteer`;
      // test
    }
    try {
      setErrorText("");
      setLoading(true);

      const res = await axios.post(airtableURL, data);
      console.log("status: ", res);
      if (res.data.statusCode === 400) {
        console.log("res.data", res.data);
        setErrorText(res.data.message);
        setLoading(false);
      } else if (res.data.statusCode === 200) {
        setResult("Submitted successfully");
        setLoading(false);
      }
    } catch (err) {
      console.log("catch error", err);
      setErrorText(err.message);
      setLoading(false);
    }
  };

  const birthDaytitleRef = useRef();

  const scrollToBirthDay = () => {
    birthDaytitleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCalendarClose = () => {
    // console.log("date selected", birthDay);
    const ageDifMs = new Date(2022, 5, 11) - new Date(birthDay).getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    // console.log("age: ", age);
    if (age < 18 || age > 28) {
      setBirthDateError("Must be between 18 - 28 as of 11 June 2022");
    } else if (!age || birthDay === "") {
      setBirthDateError("empty");
    } else {
      setBirthDateError("updated");
    }
  };

  const handleVolunteerExp = e => {
    setVolunteerExp(e.target.value);
  };

  const agreeHandler = e => {
    // console.log("agree:", e.target.checked);
    setAgree(e.target.checked);
  };
  const declareHandler = e => {
    // console.log("agree:", e.target.checked);
    setDeclare(e.target.checked);
  };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Volunteer Form" />
        <iframe
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUN0VVQU00SkZNMkRaTUdMMlRMUldFTEhOTC4u&embed=true"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          style={{
            width: "100%",
            height: "1500px",
            border: "none",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          allowFullScreen
          webkitallowfullscreen
          mozallowfullscreen
          msallowfullscreen
        />

        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-10 py-5 ">
            {/* <h1 className="text-swa-3">Volunteer Application Form</h1> */}

            {/* <p className="text-red-800">Thank you for your interest in volunteering with us. Currently, we are not accepting new volunteer applications. Please check back soon as we look forward to opening applications again in the near future</p> */}
            {/* {errorText && <Error msg={errorText} />}
            {isSubmitted && result && (
              <Success msg={result}>
                You will be notified for an interview if you are shortlisted.
              </Success>
            )}
            {(!isSubmitted &&
              birthdateError === "empty" &&
              birthdateError === "updated") ||
              (!result && (
                <form
                  className="max-w-xl mt-5 space-y-5"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                      <div>
                        <h3 className="text-lg font-bold leading-6 text-swa-1">
                          Your information
                        </h3>
                      </div>
                      <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="name"
                            className={
                              errors.name
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Name (as in NRIC)
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("name", {
                                required: true,
                                maxLength: 100,
                              })}
                              type="text"
                              name="name"
                              id="name"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            />
                            {errors.name?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                A name is required
                              </p>
                            )}
                            {errors.name?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            ref={birthDaytitleRef}
                            htmlFor="birthday"
                            className={
                              errors.birthday
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Date of Birth
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-1">
                            <div className="flex content-center justify-start w-full">
                              <DatePicker
                                selected={birthDay}
                                onChange={date => {
                                  setValue(`birthday`, date);
                                  setBirthDay(date);
                                }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd MMMM yyyy"
                                dateFormatCalendar="MMMM"
                                dropdownMode="select"
                                className="input-text"
                                calendarClassName=""
                              />
                              <CalendarIcon className="z-20 w-6 h-6 mt-2 text-gray-400 -ml-9" />
                            </div>
                            {birthdateError !== "" &&
                              birthdateError !== "empty" &&
                              birthdateError !== "updated" && (
                                <p className="text-sm text-red-500 ">
                                  {birthdateError}
                                </p>
                              )}
                            {birthdateError === "empty" &&
                              !watch("birthday") && (
                                <p className="text-sm text-red-500 ">
                                  Please indicate your date of birth
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="nationality"
                            className={
                              errors.nationality
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Nationality
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex content-center justify-start w-full">
                              <select
                                {...register("nationality", {
                                  required: true,
                                })}
                                type="text"
                                name="nationality"
                                id="nationality"
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                              >
                                {nationalities &&
                                  nationalities.map((nationality, i) => {
                                    return (
                                      <option key={i} value={nationality}>
                                        {nationality}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            {errors.nationality?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                Nationality is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="marital"
                            className={
                              errors.marital
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Marital status
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex content-center justify-start w-full">
                              <select
                                {...register("marital", {
                                  required: true,
                                  maxLength: 100,
                                })}
                                id="marital"
                                name="marital"
                                type="text"
                                autoComplete="off"
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm appearance-none focus:ring-swa-1 focus:border-swa-3 sm:"
                              >
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Separated">Separated</option>
                                <option value="Widowed">Widowed</option>
                              </select>
                            </div>
                            {errors.marital?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                Marital status is required
                              </p>
                            )}
                            {errors.marital?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="occupation"
                            className={
                              errors.occupation
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Occupation status
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex content-center justify-start w-full">
                              <select
                                {...register("occupation", {
                                  required: true,
                                  maxLength: 100,
                                })}
                                id="occupation"
                                name="occupation"
                                type="text"
                                autoComplete="off"
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm appearance-none focus:ring-swa-1 focus:border-swa-3 sm:"
                              >
                                <option value="" defaultValue disabled hidden>
                                  Choose here
                                </option>
                                <option value="Student">Student</option>
                                <option value="Employed">Employed</option>

                                <option value="Retiree or Homemaker" default>
                                  Retiree or Homemaker
                                </option>
                                <option value="Unemployed">Unemployed</option>
                                <option value="Self-employed">
                                  Self-employed
                                </option>
                              </select>
                            </div>
                            {errors.occupation?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                Occupation is required
                              </p>
                            )}
                            {errors.occupation?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        {watch("occupation") === "Student" && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                            <label
                              htmlFor="school"
                              className={
                                errors.name
                                  ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                  : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                              }
                            >
                              Name of school/institution
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                {...register("school", {
                                  maxLength: 100,
                                })}
                                type="text"
                                name="school"
                                id="school"
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                              />

                              {errors.school?.type === "maxLength" && (
                                <span className="text-sm text-red-500">
                                  You have exceeded maximum length of 100
                                  characters
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        {(watch("occupation") === "Employed" ||
                          watch("occupation") === "Self-employed") && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                            <label
                              htmlFor="employer"
                              className={
                                errors.name
                                  ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                  : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                              }
                            >
                              Name of Company/Business and job function
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                {...register("employer", {
                                  maxLength: 200,
                                })}
                                type="text"
                                name="employer"
                                id="employer"
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                              />

                              {errors.employer?.type === "maxLength" && (
                                <span className="text-sm text-red-500">
                                  You have exceeded maximum length of 200
                                  characters
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="languagespoken"
                            className={
                              errors.languagespoken
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Language&#40;s&#41; spoken &#40;include
                            dialects&#41;
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("languagespoken", {
                                maxLength: 100,
                              })}
                              type="text"
                              name="languagespoken"
                              id="languagespoken"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            />

                            {errors.languagespoken?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="languagewritten"
                            className={
                              errors.languagewritten
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Language&#40;s&#41; written
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("languagewritten", {
                                maxLength: 100,
                              })}
                              type="text"
                              name="languagewritten"
                              id="languagewritten"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            />

                            {errors.languagewritten?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="home"
                            className={
                              errors.home
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Home number
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("home", {
                                maxLength: 20,
                              })}
                              id="home"
                              name="home"
                              type="text"
                              autoComplete="home"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            />

                            {errors.home?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="mobile"
                            className={
                              errors.mobile
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Mobile number
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("mobile", {
                                required: true,
                                maxLength: 20,
                              })}
                              id="mobile"
                              name="mobile"
                              type="text"
                              autoComplete="mobile"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            />
                            {errors.mobile?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                A contact number is required
                              </p>
                            )}
                            {errors.mobile?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="email"
                            className={
                              errors.email
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                            Email address
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              {...register("email", {
                                required: true,
                                maxLength: 100,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                            />
                            {errors.email?.type === "required" && (
                              <p className="text-sm text-red-500 ">
                                Email address is required
                              </p>
                            )}
                            {errors.email?.type === "maxLength" && (
                              <span className="text-sm text-red-500">
                                You have exceeded maximum length of 100
                                characters
                              </span>
                            )}
                            {errors.email?.type === "pattern" && (
                              <span className="text-sm text-red-500">
                                Please enter a valid email format
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid-cols-1 sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="address"
                        className={
                          errors.address
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Mailing address
                      </label>
                      <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          {...register("address", {
                            maxLength: 2000,
                          })}
                          id="address"
                          name="address"
                          rows={4}
                          className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                          defaultValue={""}
                        />

                        {errors.address?.type === "maxLength" && (
                          <p className="text-sm text-red-500 ">
                            2000 max characters allowed
                          </p>
                        )}
                        <span className="absolute right-0 text-xs text-gray-400 -bottom-5">
                          count: {count && count} (max 2000 char)
                        </span>
                      </div>
                    </div>

                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Type of volunteer activity
                        <span className="block max-w-2xl mt-1 text-sm font-normal text-gray-500">
                          Please select at least one
                        </span>
                      </h3>
                      <fieldset className="space-y-5">
                        <legend className="sr-only">Notifications</legend>
                        {types.map((type, i) => {
                          return (
                            <div key={i}>
                              <label
                                htmlFor={type.value}
                                className="inline-flex items-center text-gray-700"
                              >
                                <input
                                  {...register("type", { required: true })}
                                  type="checkbox"
                                  id={type.value}
                                  value={type.value}
                                  className="w-4 h-4 mr-2 border-gray-300 rounded text-swa-3 focus:ring-swa-1"
                                />
                                <span className="ml-1">{type.name}</span>
                              </label>
                            </div>
                          );
                        })}
                        {errors.type?.type === "required" && (
                          <p className="text-sm text-red-500 ">
                            Please select at least one
                          </p>
                        )}
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                          <label
                            htmlFor="volunteerothers"
                            className={
                              errors.volunteerothers
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 sm:col-span-3 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2 sm:col-span-3`
                            }
                          >
                            Please provide more details if you have selected
                            Others.
                          </label>
                          <div className="relative mt-1 sm:mt-0 sm:col-span-3">
                            <textarea
                              {...register("volunteerothers", {
                                maxLength: 2000,
                              })}
                              id="volunteerothers"
                              name="volunteerothers"
                              rows={4}
                              className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                              defaultValue={""}
                            />

                            {errors.volunteerothers?.type === "maxLength" && (
                              <p className="text-sm text-red-500 ">
                                2000 max characters allowed
                              </p>
                            )}
                          </div>
                        </div>
                      </fieldset>

                      <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                        <h3 className="text-lg font-bold leading-6 text-swa-1">
                          Volunteering experience
                        </h3>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                        <label
                          htmlFor="experience"
                          className={
                            errors.experience
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          No of years in volunteering experience
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("experience", {
                              maxLength: 100,
                            })}
                            type="number"
                            name="experience"
                            id="experience"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                            onChange={e => setVolunteerExp(e.target.value)}
                          />

                          {errors.experience?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      {volunteerExp > 0 && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                          <label
                            htmlFor="school"
                            className={
                              errors.name
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 sm:col-span-3 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2 sm:col-span-3`
                            }
                          >
                            Provide more information about your past volunteer
                            experience. (eg, the organisation name you have
                            volunteered, the activities you were involved and
                            the year of activities.
                          </label>
                          <div className="relative mt-1 sm:mt-0 sm:col-span-3">
                            <textarea
                              {...register("experiencedetail", {
                                maxLength: 2000,
                              })}
                              id="experiencedetail"
                              name="experiencedetail"
                              rows={4}
                              className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                              defaultValue={""}
                            />

                            {errors.commitment?.type === "maxLength" && (
                              <p className="text-sm text-red-500 ">
                                2000 max characters allowed
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                        <h3 className="text-lg font-bold leading-6 text-swa-1">
                          Commitment and Availability
                        </h3>
                        <span className="block max-w-2xl mt-1 text-gray-500">
                          Let us know how much time you are committed to the
                          volunteering activities and your the days and time
                          slots that you are available.
                        </span>
                      </div>

                      <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          {...register("commitment", {
                            maxLength: 2000,
                          })}
                          id="commitment"
                          name="commitment"
                          rows={4}
                          className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                          defaultValue={""}
                        />

                        {errors.commitment?.type === "maxLength" && (
                          <p className="text-sm text-red-500 ">
                            2000 max characters allowed
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="grid-cols-1 sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="message"
                        className={
                          errors.message
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Additional Message
                        <span className="block max-w-2xl mt-1 text-gray-500">
                          Let us know a more about you, your skills and any
                          other information you would like to provide.
                        </span>
                      </label>
                      <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          {...register("message", {
                            maxLength: 2000,
                          })}
                          id="message"
                          name="message"
                          rows={4}
                          className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                          defaultValue={""}
                        />

                        {errors.message?.type === "maxLength" && (
                          <p className="text-sm text-red-500 ">
                            2000 max characters allowed
                          </p>
                        )}
                        <span className="absolute right-0 text-xs text-gray-400 -bottom-5">
                          count: {count && count} (max 2000 char)
                        </span>
                      </div>
                    </div>

                    <div className="pt-8 space-y-6 divide-y divide-gray-200 sm:pt-10 sm:space-y-5">
                      <div>
                        <h3 className="text-lg font-bold leading-6 text-swa-1">
                          Agreement &amp; Declarations
                        </h3>
                      </div>
                      <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">
                        <div className="pt-6 sm:pt-5">
                          <div role="group" aria-labelledby="label-email">
                            <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-baseline">
                              <div className="mt-4 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg space-y-4">
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        id="declaration"
                                        name="declaration"
                                        type="checkbox"
                                        className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                        onChange={e => declareHandler(e)}
                                      />
                                    </div>
                                    <div className="ml-3 ">
                                      <label
                                        htmlFor="declarations"
                                        className="font-medium text-gray-700"
                                      >
                                        Declarations
                                      </label>

                                      <p className="text-gray-500">
                                        I have not been arrested, indicated, or
                                        summoned into court as a defendant in a
                                        criminal proceeding, or convicted,
                                        fined, or imposed for the violation of
                                        any law &#40;excluding minor traffic
                                        violations&#41; in any country.
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="relative flex items-start">
                                      <div className="flex items-center h-5">
                                        <input
                                          id="offers"
                                          name="offers"
                                          type="checkbox"
                                          className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                          onChange={e => agreeHandler(e)}
                                        />
                                      </div>
                                      <div className="ml-3 ">
                                        <label
                                          htmlFor="offers"
                                          className="font-medium text-gray-700"
                                        >
                                          Agreement
                                        </label>

                                        <p className="mt-3 text-gray-500">
                                          I hereby declare that the information
                                          furnished on this application is true
                                          and accurate, I consent to having my
                                          personal data recorded in the relevant
                                          database&#40;s&#41; of Singapore
                                          Women's Association; to be contacted
                                          by the organization's relevant
                                          departments, and to accept the
                                          organization's decision on my
                                          application to be a volunteer.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="flex justify-end">
                      {!isSubmitting && (
                        <button
                          type="submit"
                          disabled={
                            !isDirty ||
                            errors.name ||
                            errors.email ||
                            errors.mobile ||
                            errors.marital ||
                            errors.nationality ||
                            errors.occupation ||
                            errors.type ||
                            !agree ||
                            !declare
                          }
                          className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                        >
                          Submit
                        </button>
                      )}
                      {loading && <>
                        <Ring color="rgb(112,48,140)" />
                        <span className="font-bold text-md text-swa-3">
                          please wait...
                        </span>
                      </>
                      }
                      {errorText !== "" && <TextError>{errorText}</TextError>}
                    </div>
                  </div>
                </form>
              ))} */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default VolunteerForm;
